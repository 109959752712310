import { solidstate } from ":shared/utils/state-manager";
import { getEvaluations } from "./apis/get-evaluations";
import { EvaluationModal } from "./evaluation-modal";
import { Table } from "./components/table";
import { global } from ":global";
import { updateEvaluation } from "./apis/update-evaluation";
import { createMemo } from "solid-js";
export default function () {
  const is_judge = global.api.auth.events?.getTokenKey() !== "evaluator";
  const $teams = solidstate.createAsync(getEvaluations);
  const $filter = solidstate.create(undefined as "graded" | "ungraded");
  const $search = solidstate.create(undefined as string);
  const counts = createMemo(() => {
    const history_length = $teams.value?.history?.length ?? 0;
    const teams_length = $teams.value?.teams?.length ?? 0;

    return {
      total: teams_length,
      graded: history_length,
      ungraded: teams_length - history_length,
    };
  });

  return (
    <section class="flex flex-col w-full h-full px-2 py-2 ">
      <h1>{is_judge ? "Judging" : "Evaluation"}</h1>
      {/* search */}
      <input
        class="form-input flex-1  caret-orange cursor-text text-1rem bg#bg-default text-black "
        placeholder="search by team name..."
        type="search"
        oninput={(e) => {
          const value = e.currentTarget.value?.toLowerCase();
          console.log("search ", $search.value);
        }}
      />
      {/*filters */}
      <div
        class={`
          flex flex-row my-2 gap-x-2
          children:(flex flex-row flex-1 h-6vh items-center rounded-md px-8 justify-between gap-x-2 text#pt whitespace-nowrap cursor-pointer )
          <lg:(flex-wrap gap-y-2)
        `}
      >
        <p class="bg#success  " onclick={() => $filter.set("graded")}>
          <span>Graded</span>
          <span class="flex flex-row w-2rem h-1rem items-center justify-center  rounded-full bg-white text#success text-0.7rem ">
            {counts().graded}
          </span>
        </p>
        <p class="bg#a " onclick={() => $filter.set("ungraded")}>
          <span>Not Graded </span>
          <span class="flex flex-row w-2rem h-1rem items-center justify-center  rounded-full bg-white text#a text-0.7rem ">
            {counts().ungraded}
          </span>
        </p>
        <p class="bg#p " onclick={() => $filter.set(undefined)}>
          <span>Total</span>
          <span class="flex flex-row w-2rem h-1rem items-center justify-center  rounded-full bg-white text#p text-0.7rem ">
            {counts().total}
          </span>
        </p>
      </div>
      {/* table */}
      <Table
        rows={$teams.value?.teams}
        fallback={
          <div class="flex flex-row items-center justify-center py-2 w-full">
            {$teams.state.loading ? "loading" : $teams.error ?? "no teams to evaluate"}
          </div>
        }
        classes={{
          desktop: {
            headersContainer: "bg#p text#pt py2 rounded-xl",
            rowsContainer: "space-y-2 mt-2",
            singleRowContainer: "bg#bg-default min-h-3rem rounded-2xl cursor-pointer",
            singleRowContainerActive: "bg!a text!pt min-h-3rem rounded-2xl cursor-pointer",
            allSlots: "px4",
          },
          phone: {
            cardsContainer: "space-y-5 !h-full",
            singleCardContainer:
              "shadow-md w-full !min-h-12rem py-2 border-2 border#bg-default border-solid rounded-xl !justify-between",
            singleCardContainerActive: " border-black bg#a !text-white children:(!text-white)",
            allCardsRows: "!w-90% !justify-betwen py-1.5",
            allCardsRowsActive: "children:(text-white)",
            singleCardHeader: "!text-0.5rem text-gray w-full",
            singleCardValue: "w-full !text-0.45rem !text-end !items-end !justify-end",
          },
        }}
        events={{
          isActiveRow: (item) => item.scores !== undefined && item.scores !== null,
          isHidden: (item) => {
            if ($filter.value === "graded" && !item.scores) {
              return true;
            }
            if ($filter.value === "ungraded" && item.scores) {
              return true;
            }
            if ($search.value) {
              if (item?.team_name.toLowerCase()?.indexOf($search.value) < 0) {
                return true;
              }
            }
            return false;
          },
        }}
        triggers={{
          onRowClicked(item, index) {
            global.store.actions.popop.pushModal({
              element(action) {
                return <EvaluationModal data={$teams as any} idx={index()} onSubmit={updateEvaluation} />;
              },
              title: "Evaluate Team",
              startExpanded: true,
            });
          },
        }}
        columns={{
          ID: {
            key: "id",
            desktop: {
              width: "8%",
            },
          },
          "Team Name": {
            key: "team_name",
            desktop: {
              class: "flex-1",
            },
          },
          Grade: {
            desktop: {
              width: "15%",
            },
            key: (r) => r?.scores?.total ?? "Not Graded",
          },
          Status: {
            desktop: {
              width: "12%",
            },
            key: (r, { active }) => {
              const status = !r.scores ? "pending" : "done";
              return (
                <span
                  class={`capitalize rounded-xl px-2 py-1 text-0.64rem h-fit
              ${
                status === "pending"
                  ? "bg-orange-100 text-orange-400"
                  : active()
                  ? "bg-[#85B1AD40] text-white"
                  : "bg-[#85B1AD40] text-[#85B1AD]"
              }`}
                >
                  {status}
                </span>
              );
            },
          },
          "Evaluation Time": {
            desktop: {
              width: "20%",
            },
            key: (r) => r?.eval_time,
          },
        }}
      />
    </section>
  );
}
